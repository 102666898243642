/**
 * Input Schema of Wallet
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {Wallet} from '@/model/resource/Wallet'
import {DappCollection} from '@/model/collection/DappCollection'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {DappConnectionCollection} from '@/model/collection/DappConnectionCollection'
import {PlatformTypeCollection} from '@/model/collection/PlatformTypeCollection'

/* TODO: review generated schema */
export class InputWalletSchema extends DefaultSchema {
  collectionDapp = new DappCollection().noPagination()
  collectionBlockchain = new BlockchainVersionCollection().noPagination()
  collectionPlatformType = new PlatformTypeCollection().noPagination()
  collectionDappConnection = new DappConnectionCollection().noPagination()

  readonly name = 'InputWallet'

  readonly fieldSet: FieldSet<Wallet> = {
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 70,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    order: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    websiteUrl: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 70,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    blockchainVersion: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionBlockchain.items,
        required: true,
        inputClass: 'multiselect--hide-checkbox',
        validation: 'required',
      },
    }),
    platformType: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionPlatformType.items,
        required: true,
        validation: 'required',
        inputClass: 'multiselect--hide-checkbox',
      },
    }),
    dappConnection: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        inputClass: 'multiselect--hide-checkbox',
        items: this.collectionDappConnection.items,
      },
    }),
    description: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'textarea',
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
        class: 'col-span-2',
        rows: 5,
      },
    }),
    logoUrl: (schema): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'wallet',
        compressDimension: $.auth.global.dappImageDimension,
        crop: false,
        class: 'scoped__image-url',
      },
    }),
  }
}
