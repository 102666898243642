






























































import {Component, Prop, Provide, Vue} from 'vue-property-decorator'
import {Wallet} from '@/model/resource/Wallet'
import {InputWalletSchema} from '@/schema/resource/Wallet/InputWalletSchema'

@Component
export default class PersistWalletView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputWalletSchema()
  wallet = new Wallet()

  async created() {
    this.populateResource()
    await this.populate()
  }

  populateResource() {
    this.schema.collectionDapp.queryAsPage()
    this.schema.collectionBlockchain.queryAsPage()
    this.schema.collectionPlatformType.queryAsPage()
    this.schema.collectionDappConnection.queryAsPage()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.wallet.getWallet(id)
    }

    this.$await.done('getWallet')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.wallet.persistWallet()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/wallet/list')
  }
}
